module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-twind/gatsby-browser.js'),
      options: {"plugins":[],"config":"twind.config.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Storeberry","short_name":"Storeberry","description":"Storeberry","start_url":"/hk","background_color":"#f8f6fc","theme_color":"#4c269a","display":"standalone","lang":"hk","icon":"static/favicon/apple-touch-icon.webp","icons":[{"src":"static/favicon/android-chrome-192x192.webp","sizes":"192x192"},{"src":"static/favicon/android-chrome-512x512.webp","sizes":"512x512"}],"localize":[{"start_url":"/en/","lang":"en","name":"Storeberry","short_name":"Storeberry","description":"Storeberry"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f9f22799488f296f0c6d84ef796dfdce"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["hk","en"],"defaultLanguage":"hk","fallbackLanguage":"hk","redirect":false,"generateDefaultLanguagePage":false,"siteUrl":"http://localhost:8003","i18nextOptions":{"interpolation":{"escapeValue":false},"nsSeparator":false},"pages":[{"matchPath":"/:lang?/:slug","getLanguageFromPath":true},{"matchPath":"/:lang?/:slug/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/tag/:uid","getLanguageFromPath":true}]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
