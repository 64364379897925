import { defineConfig } from '@twind/core';
import presetAutoprefix from '@twind/preset-autoprefix';
import presetTailwind from '@twind/preset-tailwind';

export default defineConfig({
  presets: [presetAutoprefix(), presetTailwind()],
  /* @twind/with-sveltekit will use hashed class names in production by default
   * If you don't want this, uncomment the next line
   */
  theme: {
    container: {
      center: true,
    },
    extend: {
      colors: {
        "transparent": "transparent",
        "primary": "#5D49BB",
        "purple": {
          50: "#F0EDF8",
          100: "#C5BDE6",
          200: "#9588D3",
          300: "#8171CA",
          400: "#6E5CC2",
          500: "#5D49BB",
          600: "#5240AA",
          700: "#4A3A99",
          800: "#43348A",
          900: "#362A70"
        }
      },
      display: ["group-focus", "group-hover"],
    },
  },
  hash: false,
})
